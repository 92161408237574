<template>
	<div
		class="size-px-14 mt-50 pa-20"
	>
		<div >
			<div>
				<div class="justify-space-between items-center">
					{{ title_settlement_amount }}

					<span class="size-px-12 color-gray01"> {{ txt_settlement_amount }}</span>
				</div>
				<div class="mt-15">
					<div class="box-input radius-20 justify-end gap-10 items-center bg-gray-light">
						<span class="size-px-16 font-weight-700">{{ expectation_revenues | makeComma }}</span>
						<div>원</div>
					</div>
				</div>
			</div>
			<div class="mt-20">
				<div class="justify-space-between items-center">
					{{ title_availy_amount }}
				</div>
				<div class="mt-15">
					<div class="box-input radius-20 justify-end gap-10 items-center">
						<input v-model="settle_applct_amount" type="number" :rules="$rules.max(item, 'settle_applct_amount', 9)" class="text-right font-weight-700 color-blue02 size-px-16" @keyup="checkAmount"/>
						<div>원</div>
					</div>
				</div>
			</div>
		</div>
		<hr class="under-line mt-20"/>
		<div
			class="mt-20"
		>
			<div class="justify-space-between items-center">
				카르텔
				<span class="font-weight-bold">{{ item_cartel.cartl_name}}</span>
			</div>
			<div
				v-if="view_type == 'support'"
				class="mt-10 justify-space-between items-center"
			>
				정산 신청 환산 금액
				<span class="font-weight-bold">{{ fee_plan | makeComma }}원</span>
			</div>
			<div class="mt-10 justify-space-between items-center">
				플랜 수수료({{ commis_rate }}%)
				<span class="font-weight-bold">{{ fee_plan | makeComma }}원</span>
			</div>
			<div class="mt-10 justify-space-between items-center">
				부가세({{ item_fee.vat_rate}}%)
				<span class="font-weight-bold">{{ fee_vat | makeComma }}원</span>
			</div>
			<div class="mt-10 justify-space-between items-center">
				출금 수수료
				<span class="font-weight-bold">{{ item_fee.withdrawal_commis | makeComma }}원</span>
			</div>
			<div class="mt-10 justify-space-between items-center">
				최종 정산 금액
				<span class="size-px-16 font-weight-700 color-blue02">{{ total_amount | makeComma }}원</span>
			</div>
			<div class="mt-10 justify-space-between ">
				입금 계좌
				<span class="font-weight-500 text-right">{{ item_bank.finaccom_name }} {{ item_bank.finac_info }} <br/> {{ item_bank.finac_owner_name }}</span>
			</div>
			<div
				class="mt-15 btn_wrap"
			>
				<button
					class="btn_l btn_outline_blue"
					@click="onBankAdd"
				>입금계좌 변경</button>
			</div>
		</div>
		<hr class="under-line mt-20"/>
		<div class="mt-20">
			<div class="font-weight-500 size-px-16">유의사항</div>
			<ul>
				<template
					v-if="view_type == 'subscribe'"
				>
					<li class="mt-15">정산 신청 금액은 5만원 이상 가능합니다.</li>
					<li class="mt-15">정산 신청 금액 설정은 1만원 단위로 신청 가능합니다.</li>
				</template>
				<template
					v-else
				>
					<li class="mt-15">정산 신청 수량은 500개 이상 가능합니다.</li>
					<li class="mt-15">정산 신청 수량 설정은 100개 단위로 신청 가능합니다.</li>
				</template>
				<li class="mt-15">영업일 기준 7일 이내에 등록된 계좌로 지급됩니다.</li>
			</ul>
			<div
				class="mt-15 btn_wrap"
			>
				<button
					class="btn_l btn_fill_blue"
					:disabled="disabled_settlement"
					@click="onPin"
				>정산 신청</button>
			</div>
		</div>
		<Popup_confirm
			v-if="is_on_bank"

			@click="onBankAdd"
			@cancel="is_on_bank = false"
			:not_cancel="true"
		>
			<template v-slot:title>정산 계좌 등록</template>
			<template
				v-slot:sub-txt
			>
				정산 신청을 위해 계좌 등록이 필수입니다.
			</template>
		</Popup_confirm>

		<PopupLayer
			v-if="is_on_add_bank"
		>
			<template
				v-slot:body
			>
				<div class="bg-white  radius-20">
					<div class="pa-20">
						<div class="mt-10 size-px-20 font-weight-700 color-blue02 text-center">계좌 등록</div>
						<hr class="under-line mt-20"/>
						<div class="mt-20 text-center font-weight-700 size-px-16">정산받을 계좌를 등록하세요</div>
						<div
							class="mt-20 box-input radius-20 justify-space-between gap-10 items-center"
							@click="onBankList"
						>
							{{ bank_info }}
							<v-icon>mdi-chevron-down</v-icon>
						</div>
						<div class="mt-15 box-input radius-20 ">
							<input v-model="item_bank.finac_info" class="text-left " placeholder="계좌번호를 입력하세" />
						</div>
						<div class="mt-15 box-input radius-20 ">
							<input v-model="item_bank.finac_owner_name" class="text-left " placeholder="입금자명을 입력하세요"/>
						</div>
					</div>
					<div class="btn_wrap mt-10">
						<button
							class=" pop_btn btn_fill_blue"
							@click="postFinance"
							:disabled="disabled_add"
						>확인</button>
					</div>
				</div>
			</template>

		</PopupLayer>

		<PopupHalf
			v-if="is_on_bank_list"
			:user="user"
			:title="title_bank_list"

			@cancel="is_on_bank_list = false"
		>
			<template
				v-slot:half_items
			>
				<li
					v-for="(bank, b_index) in items_bank"
					:key="'bank_' + b_index"
					class="pb-10"
					@click="setBank(bank)"
				>{{ bank.finaccom_name }}</li>
			</template>
		</PopupHalf>
	</div>
</template>

<script>
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	import PopupLayer from "@/view/Layout/PopupLayer";
	import PopupHalf from "@/view/Layout/PopupHalf";
	export default {
		name: 'CalculateItem'
		, components: {PopupHalf, PopupLayer, Popup_confirm}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'CalculateItem'
					, title: '정산 신청'
					, type: 'mypage'
					, not_footer: true
				}
				, settle_applct_amount: ''
				, item: {
				}
				, item_cartel: {
				}
				, item_bank: {
					finaccom_code: ''
					, finac_info: ''
					, finac_owner_name: ''
				}
				, is_on_bank: false
				, is_on_add_bank: false
				, is_on_bank_list: false
				, items_bank: []
				, item_fee: {

				}
				, minimum_amount: this.$route.params.type == 'subscribe' ? 50000 : 500
				, limit_pow: this.$route.params.type == 'subscribe' ? 4 : 2
				, t: ''
				, is_my_bank: false
				, view_type: this.$route.params.type
				, pinnumber: ''
			}
		}
		, computed: {
			bank_info: function(){
				let t = '은행을 선택하세요'
				if(this.item_bank.finaccom_code){
					t = this.item_bank.finaccom_name
				}
				return t
			}
			, disabled_add: function(){
				let t = true
				if(this.item_bank.finaccom_code && this.item_bank.finac_info && this.item_bank.finac_owner_name){
					t = false
				}
				return t
			}
			, title_bank_list: function(){
				let t = '은행선택'

				return t
			}
			, fee_plan: function(){
				let t = 0

				if(this.settle_applct_amount && this.item_fee.subscrp_plan_commis_rate){
					t = Math.ceil(Number(this.settle_applct_amount) * Number(this.item_fee.subscrp_plan_commis_rate) / 100)
				}
				return t
			}
			, fee_vat: function(){
				let t = 0
				if(this.settle_applct_amount && this.item_fee.vat_rate){
					t = Math.ceil(Number(this.settle_applct_amount) * Number(this.item_fee.vat_rate) / 100)
				}
				return t
			}
			, total_amount: function(){
				let t = 0
				if(this.settle_applct_amount && this.item_fee.subscrp_plan_commis_rate && this.item_fee.vat_rate){
					t = this.settle_applct_amount
					t -= this.fee_plan
					t -= this.fee_vat
					t -= this.item_fee.withdrawal_commis
				}
				if(t < 0){
					t = 0
				}
				return t
			}
			, disabled_settlement: function(){
				let t = true
				if(Number(this.settle_applct_amount) >= this.minimum_amount){
					t = false
				}
				return t
			}
			, title_settlement_amount: function(){
				let t = '정산 가능 금액'
				if(this.view_type == 'support'){
					t = '정산 가능 하트 수량'
				}
				return t
			}
			, txt_settlement_amount: function(){
				let t = '지난달까지 금액 기준'
				if(this.view_type == 'support'){
					t = '지난달까지 하트'
				}
				return t
			}

			, title_availy_amount: function(){
				let t = '정산 신청 금액'
				if(this.view_type == 'support'){
					t = '정산 신청 수량'
				}
				return t
			}
			, commis_rate: function(){
				let t = this.item_fee.subscrp_plan_commis_rate
				if(this.view_type == 'support'){
					t = this.item_fee.point_withdrawal_commis_rate
				}

				return t
			}
			, expectation_revenues: function(){
				let t = this.item.subscrp_expectation_revenues
				if(this.view_type == 'support'){
					t = this.item.spnsh_expectation_revenues
				}
				return t
			}
		}
		, methods: {
			getMyInfo: async function(){

				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_finance
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.item_bank = result.data
						if(!this.item_bank.finaccom_code){
							this.is_on_bank = true
						}else{
							this.is_my_bank = true
							await this.getExpect()
						}
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
				}
			}
			, onBankAdd: function(){
				this.is_on_bank = false
				this.is_on_add_bank = true
			}
			, onBankList: function(){
				this.is_on_bank_list = true
			}
			, getBankList: async function(){
				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_finance_list
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.items_bank = result.data.finaccom_list
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
				}
			}
			, postFinance: async function(){
				try {
					let url = this.$api_url.api_path.post_finance
					if(this.is_my_bank){
						url = this.$api_url.api_path.put_finance
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, finaccom_code: this.item_bank.finaccom_code
							, finac_info: this.item_bank.finac_info
							, finac_owner_name: this.item_bank.finac_owner_name
						}
						, type: true
					})

					if(result.success){
						await this.getMyInfo()
						this.is_on_bank = false
						this.is_on_add_bank = false
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, setBank: function(item){
				this.item_bank = item
				this.item_bank.finaccom_code = item.finaccom_code
				this.is_on_bank_list = false
			}
			, getExpect: async function(){

				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_finance_expect
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item = result.data
						await this.getExpectFee()
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
				}
			}
			, getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
					})

					if(result.success){
						this.item_cartel = result.data
						await this.getMyInfo()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, getExpectFee: async function(){

				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_finance_fee
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_fee = result.data
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
				}
			}
			, checkAmount: function(){
				clearTimeout(this.t)
				let d = '0000'
				if(this.view_type == 'support'){
					d = '00'
				}
				if(this.settle_applct_amount){

					this.t = setTimeout( () => {
						if(Number(this.settle_applct_amount) > this.minimum_amount){
							this.settle_applct_amount = (this.settle_applct_amount + '').slice(0, (this.settle_applct_amount +'').length - this.limit_pow) + d
						}
					}, 100)
				}
			}
			, postSettlement: async function(){

				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_calculate
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, settle_applct_amount: Number(this.settle_applct_amount)
							, subscrp_plan_commis: this.fee_plan
							, vat: this.fee_vat
							, withdrawal_commis: this.item_fee.withdrawal_commis
							, receive_schedule_amount: this.total_amount
							, finaccom_code: this.item_bank.finaccom_code
							, finac_info: this.item_bank.finac_info
							, finac_owner_name: this.item_bank.finac_owner_name
							, pinnumber: this.pinnumber
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('to', { name: 'SubscribeReport'})
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, onPin: function(){

				this.$bus.$emit('onPin', 'check')

				this.$bus.$off('pinCallback')
				this.$bus.$on('pinCallback', (type, pin)=> {
					// console.log(type, pin)
					this.pinnumber = pin
					this.postSettlement()
				})
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getCartel()
			this.getBankList()
		}
		, watch: {
			settle_applct_amount: {
				handler: function(call) {
					if((call+'').slice(0, 1) < 1){
						this.settle_applct_amount = (call+'').slice(1, (call+'').length - 1)
					}else{
						if(Number(call) > Number(this.item.subscrp_expectation_revenues)){
							this.$bus.$emit('notify', { type: 'error', message: '정산 가능금액을 초과하였습니다'})
							this.settle_applct_amount = Number(this.expectation_revenues)
						}
					}
				}
			}
		}
	}
</script>